<!--模板-->
<template>
  <div class="">
    <div class="right-top">
      <div class="search">
        <div style="margin-right: 36px">
          <span style="margin-right: 5px">检查版本:</span>
          <a-select placeholder="请选择" style="width: 272px" @change="handleChange">
            <a-select-option value="jack"> Jack </a-select-option>
          </a-select>
        </div>
        <a-button type="primary" style="margin-right: 5px">查询</a-button>
        <a-button>重置</a-button>
      </div>
      <div class="newBuild">
        <a-button type="primary" @click="showModal">新建</a-button>
      </div>
    </div>
    <a-table :columns="columns" :data-source="data">
      <span slot="operation">
        <a>修改</a>
        <a-divider type="vertical" />
        <a>删除</a>
        <a-divider type="vertical" />
        <a>详情</a>
      </span>
    </a-table>
  </div>
</template>

<script>
// import component from '@/component/*.vue'
export default {
  data() {
    return {
      data: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'serialNum',
          key: 'serialNum',
          scopedSlots: { customRender: 'num'},
          align: 'center',
        },
        {
          title: '检查项目',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
        },
        {
          title: '检测内容数',
          dataIndex: 'contentNum',
          key: 'contentNum',
          align: 'center',
        },
        {
          title: '总分',
          key: 'score',
          dataIndex: 'score',
          align: 'center',
        },
        {
          title: '更新时间',
          key: 'updateTime',
          dataIndex: 'updateTime',
          align: 'center',
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ]
    }
  },
  props: {
    showModals: {
      type: Function,
      default: null,
    },
    versionId: {
      type: String,
      default: ''
    }
  },
  components: {},
  computed: {},
  filters: {},
  methods: {
    showModal() {
      // this.$parent.showModal()
      this.showModals()
      // this.$emit('showModal')
    },
  },
  created() {},
  mounted() {},
}
</script>

<style lang="less" scoped>
// @import url(); 引入公共css
.right-top {
  margin-bottom: 53px;
  box-sizing: border-box;
  padding: 42px 30px 0 46px;
}
.search {
  display: flex;
  align-content: center;
}
.newBuild {
  display: flex;
  justify-content: flex-end;
}
</style>
